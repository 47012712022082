import React, { ReactElement } from "react"
import Layout from "../components/UI/Layout"
import CategoryTemplate from "../components/Sections/CategoryTemplate"
import Head from "../components/Global/Head"

const CategoryTemplatePage = ({ pageContext }: any): ReactElement => {
  console.log(pageContext)
  return (
    <Layout hideLanding={true}>
      <Head
        pageTitle="Platforma Treningowa Online" //todo
        description="" //todo
        keywords="trening online, platforma treningowa online, video trening" //todo
      />
      <CategoryTemplate name={pageContext.name} videos={pageContext.videos} />
    </Layout>
  )
}

export default CategoryTemplatePage
