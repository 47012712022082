import React, { useState, useEffect } from "react"
import {
  CardWrapper,
  VideoContent,
  VimeoPlayer,
  TrainerPicture,
  TrainerDesc,
  RowWrapper,
  Note,
  TrainingTitle,
  Price,
  LightGraySpan,
  IntensityBar,
  IntensityBarFill,
  Time,
} from "./styles"
import ITrainingCard from "./types"
import { Link } from "gatsby"
import { FlexBetween } from "../../Global"
import author_picture from "../../../data/mockData/author1_icon.png"
import { Icon } from "@iconify/react"
import starFilled from "@iconify-icons/ant-design/star-filled"
import clock24Regular from "@iconify-icons/fluent/clock-24-regular"

function injectVideo(data: any) {
  return { __html: data }
}

const TrainingCard = ({
  authorName,
  trainingTitle,
  authorPicture,
  videoSlug,
  iFrame,
  isInChallenge,
  isInPreview,
  price,
  intensity,
  rating,
  lenght,
  videoId,
  packageSlug,
  hideInfo,
  isOtherPackage,
}: ITrainingCard) => {
  const [_html, set_html] = useState<any>(null)
  useEffect(() => {
    set_html(iFrame)
  }, [])

  return (
    <CardWrapper>
      <VideoContent
        isInPreview={isInPreview && isInPreview}
        isOtherPackage={isOtherPackage && isOtherPackage}
      >
        <VimeoPlayer
          style={{ width: "100%", height: "100%" }}
          dangerouslySetInnerHTML={_html && injectVideo(_html)}
        ></VimeoPlayer>
      </VideoContent>
      {!hideInfo === true && (
        <>
          <FlexBetween>
            <RowWrapper>
              <TrainerPicture>
                <img src={author_picture} />
              </TrainerPicture>
              <TrainerDesc>{authorName}</TrainerDesc>
            </RowWrapper>
            <RowWrapper>
              <Icon
                width={24}
                height={24}
                color={"gray"}
                icon={starFilled}
              ></Icon>
              <Note>{rating}</Note>
            </RowWrapper>
          </FlexBetween>
          <FlexBetween>
            <RowWrapper>
              <Link to={`${packageSlug}/${videoSlug}-${videoId}`}>
                {isInChallenge === false ? (
                  <TrainingTitle>{trainingTitle}</TrainingTitle>
                ) : (
                  <TrainingTitle style={{ color: "white" }}>
                    {trainingTitle}
                  </TrainingTitle>
                )}
              </Link>
            </RowWrapper>
            <RowWrapper>
              {isInChallenge === false ? (
                <Price>{price} PLN</Price>
              ) : (
                <Price style={{ color: "white" }}>{price} PLN</Price>
              )}
            </RowWrapper>
          </FlexBetween>
          <FlexBetween>
            <RowWrapper>
              <LightGraySpan>INTENSYWNOŚĆ</LightGraySpan>
              <IntensityBar>
                <IntensityBarFill intensity={intensity} />
              </IntensityBar>
            </RowWrapper>
            <RowWrapper>
              <Icon
                width={24}
                height={24}
                color={"lightgray"}
                icon={clock24Regular}
              />
              <Time> {lenght}</Time>
            </RowWrapper>
          </FlexBetween>
        </>
      )}
    </CardWrapper>
    /*
    <CardWrapper isInVideoPreview={isInVideoPreview && isInVideoPreview}>
      <Content isInVideoPreview={isInVideoPreview && isInVideoPreview}>
        <AnchorLink to={videoSlug ? videoSlug : "#"}>
          <DescriptionContainer>
            <AvatarImage>
              <img src={authorPicture} />
            </AvatarImage>
            <NameContainer>
              <p>{authorName}</p>
              <p>{trainingTitle}</p>
            </NameContainer>
          </DescriptionContainer>
        </AnchorLink>
      </Content>
      <VideoContent>
        <VimeoPlayer
          style={{ width: "100%", height: "100%" }}
          dangerouslySetInnerHTML={_html && injectVideo(_html)}
        ></VimeoPlayer>
      </VideoContent>
    </CardWrapper>
    */
  )
}

export default TrainingCard
