import styled from "styled-components"
import { FlexRow } from "../../Global"
import { IVideoContent, IIntensityBarFill } from "./types"

export const RowWrapper = styled(FlexRow)`
  margin-top: 13px;
  align-items: center;
`

export const CardWrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 100;
  width: 100%;
  height: auto;
  @media (max-width: 700px) {
    height: 400px;
  }
`
export const VideoContent = styled.div<IVideoContent>`
  position: relative;
  width: 100%;
  height: ${props => (props.isInPreview === true ? "500px" : "265px")};
  height: ${props => props.isOtherPackage === true && "155px"};

  & > div:nth-child(1) {
    position: absolute;
    object-fit: cover;
    z-index: 500;
  }
`

export const VimeoPlayer = styled.div`
  background-color: black;
  position: relative;
  width: 100%;
  margin: unset;
  height: 240px;
  & > iframe {
    width: 100% !important;
    margin: unset !important;
    height: 100% !important;
  }
  & div {
    background-color: red;
  }
`

export const TrainerPicture = styled.div`
  width: 48px;
  height: 48px;
  & > img {
    width: 48px;
    height: 48px;
    border-radius: 100%;
  }
`

export const TrainerDesc = styled.span`
  color: gray;
  font-size: 24px;
  padding-left: 13px;
`

export const Note = styled.span`
  padding-left: 13px;
  font-weight: bold;
  color: gray;
  font-size: 24px;
`

export const TrainingTitle = styled.span`
  color: black;
  font-size: 24px;
`

export const Price = styled(TrainingTitle)`
  font-size: 36px;
`

export const LightGraySpan = styled.span`
  color: lightgray;
  font-size: 18px;
`
export const Time = styled(LightGraySpan)`
  padding-left: 13px;
`

export const IntensityBar = styled.div`
  margin-left: 15px;
  position: relative;
  height: 5px;
  width: 105px;
  background-color: lightgray;
  @media (max-width: 700px) {
    width: 90px;
  }
`
export const IntensityBarFill = styled.div<IIntensityBarFill>`
  position: absolute;
  left: 0px;
  top: 0px;
  height: 5px;
  width: ${props => props.intensity * 10 + "%"};
  background-color: ${props => props.theme.color.lightGreen};
`
/*
export const CardWrapper = styled.div<ICardWrapper>`
  display: flex;
  position: relative;
  z-index: 100;
  width: 100%;
  @media (min-width: 700px) {
    height: ${props => (props.isInVideoPreview ? "600px" : "240px")};
  }
  @media (max-width: 700px) {
    height: 240px;
  }
`
export const VideoContent = styled.div`
  position: relative;
  width: 60%;

  & > div:nth-child(1) {
    position: absolute;
    object-fit: cover;
    z-index: 500;
  }
`


export const Content = styled.div<IContent>`
  position: relative;
  width: 40%;
  z-index: 515;

  &:hover {
    cursor: pointer;
  }
  @media (min-width: 700px) {
    height: ${props => (props.isInVideoPreview ? "600px" : "240px")};
  }
  @media (max-width: 700px) {
    height: 240px;
  }
`

export const DescriptionContainer = styled.div`
  position: absolute;
  z-index: 110;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`

export const AvatarImage = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 515;
  left: 0;
  top: 0;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const NameContainer = styled(FlexCenterCol)`
  position: absolute;
  width: 100%;
  height: 30%;
  left: 0;
  bottom: 0;
  z-index: 516;
  background-color: rgba(0, 0, 0, 0.6);
  & > p {
    margin: 0;
    text-align: center;
    color: ${props => props.theme.color.white};
  }
`
export const VimeoPlayer = styled.div`
  background-color: black;
  position: relative;
  width: 100%;
  margin: unset;
  height: 240px;
  & > iframe {
    width: 100% !important;
    margin: unset !important;
    height: 100% !important;
  }
  & div {
    background-color: red;
  }
`
*/
